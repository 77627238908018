import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import ComponentLayout from '~/src/layouts/component-layout';
import { Link, Text } from '@primer/react';
import { Link as GatsbyLink } from 'gatsby';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Note = makeShortcode("Note");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h2>{`Usage`}</h2>
    <Note variant="warning" mdxType="Note">
  <Text sx={{
        display: 'block',
        fontWeight: 'bold',
        mb: 2
      }} mdxType="Text">Usage for this component is not encouraged</Text>
  <Text mdxType="Text">Instead, please see <Link as={GatsbyLink} to="/components/dialog" mdxType="Link">dialog</Link>.</Text>
    </Note>
    <p>{`Box overlay is only available as a `}<a parentName="p" {...{
        "href": "/deprecated-components/box-overlay/css"
      }}>{`CSS component`}</a>{`. Use box overlay with the `}<inlineCode parentName="p">{`details`}</inlineCode>{` and `}<inlineCode parentName="p">{`details-dialog`}</inlineCode>{`. The box overlay appears on top of the page content.`}</p>
    <p>{`Box overlays come in three widths, default (440px), narrow (320px), and wide (640px).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      